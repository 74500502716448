import React, { ReactNode, Ref, useState } from 'react'
import {
  Avatar,
  Badge,
  Box,
  FilterButton,
  Flex,
  Group,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceRating,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
  SkippedJustificationsInterface,
} from '@src/interfaces/performance'
import { get, set, isEmpty, lowerCase } from 'lodash'
import {
  CardContentTypes,
  cardHasMissingValues,
  getRoundedRating,
  GradeOption,
  PerformanceRatingToGraphNumber,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import styled from 'styled-components'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { relationToString } from '@src/features/Scorecard/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { InfoOutline } from '@revolut/icons'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'

const CardItemContainer = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  min-height: 80px;
  &:hover {
    background-color: ${Token.color.blue_5};
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: ${props => props.theme.zIndex.main};
  min-width: 13px;
  height: 13px;
  padding: 0;
  border: 2px solid ${Token.color.background};
  box-sizing: content-box;
`

export interface CardField {
  field: string
  fieldToSelect?: string
  title: string
  grades: GradeOption[]
  cardIndex?: number
}

interface CardProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  type: CardContentTypes
  title: string
  icon: ResolveIconType
  fields: CardField[]
  isViewMode?: boolean
  onSelectGrade?: (grade: GradeOption, field: CardField) => void
  justification?: string | SkippedJustificationsInterface[]
  isGradeSelectedRule?: (field: string, grade: GradeOption) => boolean
  headerRef?: Ref<HTMLDivElement>
  finalRating?: PerformanceRating
  additionalInfo?: ReactNode
  onHelpClick?: () => void
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
}

const ExceedingExpectationBadge = () => {
  const exceedingTooltip = useTooltip()

  return (
    <StyledBadge
      useIcon="16/ExclamationMarkSign"
      backgroundColor={Token.color.orange}
      {...exceedingTooltip.getAnchorProps()}
    >
      <Tooltip {...exceedingTooltip.getTargetProps()} placement="top-end" width={270}>
        This rating is above the expectation. Please add justification for this card.
      </Tooltip>
    </StyledBadge>
  )
}

const getLoweredType = (type: CardContentTypes | string) => {
  return lowerCase(type).replace(' ', '-')
}

interface CardItemProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  field: CardField
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
  getFieldDetails: (
    field: CardField,
    grade: GradeOption,
  ) => { selected: boolean; aboveExpectation: boolean }
  onGradeClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => void
}

const CardItem = ({
  data,
  field,
  isViewMode,
  type,
  cardItemIndex,
  getFieldDetails,
  onGradeClick,
  renderExpandedContent,
}: CardItemProps) => {
  const { errors } = useLapeContext<ReviewScorecardInterface>()
  const [expanded, setExpanded] = useState(false)

  const fieldValue = get(data, field.field)
  const hasMissingValues = cardHasMissingValues(
    type,
    fieldValue as SkillCardInterface,
    errors,
  )
  const hasErrors = !isEmpty(get(errors, field.field))
  const validationFailed = hasErrors && hasMissingValues

  return (
    <VStack>
      <CardItemContainer
        justifyContent="space-between"
        alignItems="center"
        my="s-2"
        p="s-20"
        bg={validationFailed ? Token.color.inputError : undefined}
        borderRadius={Token.radius.r12}
        onClick={() => {
          setExpanded(!expanded)
        }}
        data-testid={`card-item-${getLoweredType(type)}-${cardItemIndex}`}
      >
        <Text variant="h6" fontWeight={500} lineHeight={Token.lineHeight.caption}>
          {field.title}
        </Text>
        <Flex alignItems="center" justifyContent="flex-end">
          {!expanded && (
            <Flex gap="s-4" alignItems="center">
              {field.grades.map(grade => {
                const { selected, aboveExpectation } = getFieldDetails(field, grade)
                return (
                  <Flex
                    key={grade.key}
                    justifyContent="center"
                    style={{ position: 'relative' }}
                  >
                    {aboveExpectation && <ExceedingExpectationBadge />}
                    <FilterButton
                      onClick={e => onGradeClick(e, field, grade)}
                      active={selected}
                      style={{ height: '30px' }}
                      px="s-12"
                      disabled={isViewMode && !selected}
                      data-testid={`card-${getLoweredType(
                        type,
                      )}-${cardItemIndex}-${getLoweredType(grade.text)}${
                        selected ? '-active' : ''
                      }`}
                    >
                      <Text data-testid="button-text">{grade.text}</Text>
                    </FilterButton>
                  </Flex>
                )
              })}
            </Flex>
          )}
          <Box ml="s-8" data-testid="card-button">
            <Icon
              name={expanded ? 'ChevronDown' : 'ChevronRight'}
              size={20}
              color={Token.color.greyTone20}
            />
          </Box>
        </Flex>
      </CardItemContainer>
      {expanded &&
        renderExpandedContent({
          type,
          field: field.fieldToSelect ? field.fieldToSelect : field.field,
          cardIndex: cardItemIndex,
        })}
    </VStack>
  )
}

export const Card = ({
  data,
  type,
  title,
  icon,
  fields,
  isViewMode = false,
  onSelectGrade,
  justification,
  isGradeSelectedRule,
  headerRef,
  finalRating,
  additionalInfo,
  onHelpClick,
  renderExpandedContent,
}: CardProps) => {
  const { query } = useQuery()

  const onGradeClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => {
    if (!isViewMode) {
      e.stopPropagation()
      if (onSelectGrade) {
        onSelectGrade(grade, field)
      } else {
        set(data, field.field, grade.key)
      }
    }
  }

  const getFieldDetails = (field: CardField, grade: GradeOption) => {
    const fieldValue = get(data, field.field)
    const selected =
      isGradeSelectedRule?.(field.field, grade) ||
      grade.key === getRoundedRating(fieldValue)
    const expectedRating = fieldValue?.rating_expectation as PerformanceRating
    const aboveExpectation =
      selected &&
      PerformanceRatingToGraphNumber[grade.key] >
        PerformanceRatingToGraphNumber[expectedRating]

    return { selected, aboveExpectation }
  }

  const justifications = Array.isArray(justification) ? justification : []
  const singleViewFilter = !!justifications.length && !!query[Queries.ReviewerId]

  return (
    <Group>
      <VStack p="s-4" ref={headerRef}>
        <Flex alignItems="center" justifyContent="space-between" p="s-12">
          <Flex alignItems="center" gap="s-16" width="50%">
            <Avatar useIcon={icon} />
            <Flex alignItems="center" gap="s-8">
              <Text variant="primary" fontWeight={600}>
                {title}
              </Text>
              {onHelpClick && (
                <InfoOutline
                  cursor="pointer"
                  size={16}
                  color={Token.color.greyTone50}
                  onClick={onHelpClick}
                />
              )}
            </Flex>
          </Flex>
          {isViewMode && finalRating && (
            <Text
              variant="primary"
              color={
                finalRating === PerformanceRating.skipped
                  ? Token.color.greyTone50
                  : undefined
              }
            >
              {PerformanceRatingTitle[finalRating]}
            </Text>
          )}
        </Flex>
        {isViewMode && !!justifications.length && !singleViewFilter && (
          <VStack space="s-8">
            {justifications.map((value, i) => (
              <Box
                key={i}
                p="s-16"
                mx="s-16"
                mb={justifications.length === 1 ? 's-16' : 0}
                data-testid="skip-section"
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <Text variant="primary" use="div" mb="s-4">
                  {value.review.reviewer.display_name} (
                  {relationToString(value.review.reviewer_relation, true)}) skipped this
                  section
                </Text>
                <Text color="grey-tone-50">“{value.value}”</Text>
              </Box>
            ))}
          </VStack>
        )}
        {additionalInfo}
        {(!isViewMode || !singleViewFilter) && (
          <>
            {fields.map((field, ind) => (
              <CardItem
                key={ind}
                data={data}
                field={field}
                isViewMode={isViewMode}
                type={type}
                cardItemIndex={ind}
                getFieldDetails={getFieldDetails}
                onGradeClick={onGradeClick}
                renderExpandedContent={renderExpandedContent}
              />
            ))}
          </>
        )}
      </VStack>
    </Group>
  )
}
