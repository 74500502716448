import React, { useRef } from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Palette } from '@revolut/icons'
import {
  CardContentTypes,
  getRoundedRating,
  ratingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get } from 'lodash'
import { SkillsView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/SkillsView'

export const SkillsCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const ref = useRef<HTMLDivElement>(null)

  const canViewSkills = !!values?.summary?.functional_skills

  if (!canViewSkills) {
    return null
  }

  const hasManagerValues = !!values.summary?.manager_values

  const managerValuesCards = values.summary?.manager_values?.cards || []
  const managerValuesFields =
    managerValuesCards.map((card, ind) => ({
      field: `summary.manager_values.cards.${ind}`,
      title: card.name,
      grades: ratingOptions,
      cardIndex: ind,
    })) || []
  const functionalSkillsCards = values.summary?.functional_skills?.cards || []
  const functionalSkillsFields = functionalSkillsCards.map((card, ind) => ({
    field: `summary.functional_skills.cards.${ind}`,
    title: card.name,
    grades: ratingOptions,
    cardIndex: ind,
  }))
  const skillsFields = hasManagerValues ? managerValuesFields : functionalSkillsFields

  return (
    <Card
      data={values}
      renderExpandedContent={expContentField => (
        <SkillsView selectedField={expContentField} />
      )}
      type={CardContentTypes.SKILLS}
      title="Functional skills"
      icon={Palette}
      fields={skillsFields}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.rating
        if (!ratingValue) {
          return false
        }
        return hasManagerValues
          ? ratingValue === grade.key
          : getRoundedRating(ratingValue) === grade.key
      }}
      headerRef={ref}
      justification={
        values.summary?.manager_values?.skipped_section_justifications ||
        values.summary?.functional_skills?.skipped_section_justifications
      }
      finalRating={
        hasManagerValues
          ? values.summary?.manager_values?.rating
          : values.summary?.functional_skills?.rating
      }
      isViewMode
    />
  )
})
