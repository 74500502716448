import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface, SectionType } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'

export const DeliverablesCard = connect(({ onHelpClick }: CommonCardProps) => {
  const { values, errors } = useLapeContext<ReviewScorecardInterface>()
  const hasDeliverables = !!values.review_data.deliverables
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (errors.review_data?.deliverables) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errors.review_data?.deliverables])

  if (!hasDeliverables) {
    return null
  }

  const deliverablesGrades =
    values.review_data.deliverables?.cards?.[0].sections?.[0]?.type ===
    SectionType.SingleChoice
      ? values.review_data.deliverables.cards[0].sections[0].options.map(option => ({
          key: option.key,
          text: PerformanceRatingTitle[option.key],
          description: [option.text],
        }))
      : []

  return (
    <Card
      data={values}
      renderExpandedContent={() => <Deliverables reviewData={values.review_data} />}
      type={CardContentTypes.DELIVERABLES}
      title="Goals and deliverables"
      icon="Target"
      fields={[
        {
          field: `review_data.deliverables.cards.0`,
          title: 'Contribution and impact',
          grades: deliverablesGrades,
        },
      ]}
      onSelectGrade={grade => {
        values.review_data.deliverables?.cards?.forEach((_, ind) => {
          set(values, `review_data.deliverables.cards.${ind}.sections.0.value`, grade.key)
        })
      }}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.sections?.[0]?.value
        return !!ratingValue && ratingValue === grade.key
      }}
      justification={values?.review_data?.deliverables?.skipped_section_justification}
      headerRef={ref}
      onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
    />
  )
})
