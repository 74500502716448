import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  GradeOption,
  kpiRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { AxiosResponse } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import { Box, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { RowInterface } from '@src/interfaces/data'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'

const KPI_ROW: RowInterface<KpiInterface> = {
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiInitialValueColumn,
      width: 50,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 50,
    },
  ],
}

export const KPIsCard = connect(({ onHelpClick }: CommonCardProps) => {
  const { values, errors } = useLapeContext<ReviewScorecardInterface>()
  const hasKPI = !!values.review_data.kpis_section
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      !values.review_data.kpis_section?.recommended_rating &&
      values.review_data.kpis_section?.rating
    ) {
      set(
        values,
        'review_data.kpis_section.recommended_rating',
        getRoundedRating(values.review_data.kpis_section?.rating),
      )
    }
  }, [values.review_data.kpis_section])

  useEffect(() => {
    if (errors.review_data?.kpis_section) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errors.review_data?.kpis_section])

  const kpiSection = values.review_data.kpis_section
  const kpiTable = useTable<KpiInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: kpiSection?.kpi_items || [],
            count: kpiSection?.kpi_items.length,
          },
        } as AxiosResponse<GetRequestInterface<KpiInterface>>)
      }),
  })

  if (!hasKPI) {
    return null
  }

  return (
    <Card
      data={values}
      renderExpandedContent={() => <KPI reviewData={values.review_data} />}
      additionalInfo={
        <Box px="s-12">
          <TableWidget style={{ padding: 0 }}>
            <TableWidget.Table>
              <AdjustableTable<KpiInterface>
                name={TableNames.EmployeePerformanceKPI}
                dataType="KPI"
                row={KPI_ROW}
                {...kpiTable}
                noDataMessage="No KPI defined"
                onRowClick={onKPITableRowClick}
                noReset
                hideCountAndButtonSection
              />
            </TableWidget.Table>
          </TableWidget>
        </Box>
      }
      type={CardContentTypes.KPI}
      title="Goals"
      icon="Target"
      fields={[
        {
          field: 'review_data.kpis_section',
          title: 'Goal performance',
          grades: kpiRating as GradeOption[],
        },
      ]}
      onSelectGrade={grade => {
        set(values, `review_data.kpis_section.recommended_rating`, grade.key)
      }}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.recommended_rating
        return !!ratingValue && ratingValue === grade.key
      }}
      headerRef={ref}
      onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
    />
  )
})
