import React from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  GradeOption,
  kpiRating,
  deliverablesRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'

export const ProbationDeliverablesCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const hasKPI = !!values.summary?.kpis_section

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => <KPI isViewMode reviewData={values.summary} />}
          type={CardContentTypes.KPI}
          title="Goals and deliverables"
          icon="Target"
          fields={[
            {
              field: 'summary.kpis_section.rating',
              title: 'Contribution and impact',
              grades: kpiRating as GradeOption[],
            },
          ]}
          isViewMode
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={() => (
            <ProbationDeliverables
              reviewData={values.summary}
              category={values.reviews[0]?.category}
              cycleId={values.reviews[0]?.performance_checkpoint?.cycle_id}
              checkpointNum={values.reviews[0]?.probation_checkpoint?.number}
              comments={
                values.summary?.calculated_deliverables_ratings
                  ?.recommended_rating_comment?.ratings
              }
              isViewMode
            />
          )}
          type={CardContentTypes.DELIVERABLES}
          title="Goals and deliverables"
          icon="Target"
          fields={[
            {
              field: 'summary.calculated_deliverables_ratings.recommended_rating.rating',
              title: 'Contribution and impact',
              grades: deliverablesRating as GradeOption[],
            },
          ]}
          isViewMode
        />
      )}
    </>
  )
})
