import React, { useEffect, useRef } from 'react'
import {
  PerformanceReviewTypes,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  cultureOptions,
  getRoundedRating,
  GradeOption,
  onPrefillCultureValue,
  onPrefillWithGrade,
  ratingOptions,
  shouldScrollToError,
  updateValueRating,
  usePrefillSkillsWithPreviousQuarter,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { useGetSkillsSettings } from '@src/api/settings'
import { getNormalizedCards } from '@src/utils/performance'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import {
  Box,
  Button,
  List,
  StatusPopup,
  Text,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'
import { Gift, StarEmpty } from '@revolut/icons'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'
import { CultureValues } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/CultureValues'

export const CultureValuesCard = connect(({ onHelpClick }: CommonCardProps) => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors } = form
  const { data: skillsPreferences } = useGetSkillsSettings()
  const statusPopup = useStatusPopup()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (form.validation?.review_data?.fields?.culture_values && skillsPreferences) {
      form.validation.review_data.fields.culture_values =
        form.validation.review_data.fields.culture_values.meta({ skillsPreferences })
    }
  }, [skillsPreferences, form.validation?.review_data])

  useEffect(() => {
    if (errors.review_data?.culture_values) {
      const errorMessage = get(errors, 'review_data.culture_values.non_field_errors')?.[0]
      if (errorMessage?.length) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Please review Values section</StatusPopup.Title>
            <StatusPopup.Description>{errorMessage}</StatusPopup.Description>
            <StatusPopup.Actions>
              <Button variant="secondary" onClick={statusPopup.hide}>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      }
    }
  }, [errors.review_data?.culture_values])

  useEffect(() => {
    const shouldScrollSkills = shouldScrollToError(errors, 'review_data.culture_skills')
    const shouldScrollValues = shouldScrollToError(errors, 'review_data.culture_values')
    if (shouldScrollSkills || shouldScrollValues) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errors.review_data?.culture_skills])

  const hasCultureValues = !!values.review_data?.culture_values
  const hasCultureSkills = !!values.review_data?.culture_skills

  const cultureValuesCards = values?.review_data?.culture_values?.cards || []
  const cultureValuesFields = cultureValuesCards.map((card, ind) => ({
    field: `review_data.culture_values.cards.${ind}`,
    title: card.name,
    grades: cultureOptions as GradeOption[],
    cardIndex: ind,
  }))

  const cultureSkillsCards = getNormalizedCards(
    values?.review_data?.culture_skills?.cards || [],
  )
  const cultureSkillsFields = cultureSkillsCards.map((card, ind) => ({
    field: `review_data.culture_skills.cards.${ind}`,
    title: card.name,
    grades: ratingOptions,
    cardIndex: ind,
  }))

  // pre-filling with previous cycle values or "Performing" as a default value
  useEffect(() => {
    if (
      hasCultureValues &&
      // if first found section has value, then all cards were already pre-filled
      !values.review_data.culture_values?.cards?.[0].sections[0].value
    ) {
      values.review_data.culture_values?.cards?.forEach((card, cardIndex) => {
        card.sections.forEach(section => {
          if (!section.value) {
            section.value =
              section.previous_values?.[0]?.value || AssessBehaviourButtonTypes.neutral
          }
        })
        updateValueRating({
          values,
          path: `review_data.culture_values.cards.${cardIndex}`,
        })
      })
    }
  }, [values.review_data.culture_values?.cards])

  const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
    values,
    values?.review_data?.culture_skills?.cards,
  )

  if (!hasCultureValues && !hasCultureSkills) {
    return null
  }

  if (!prefillCompleted) {
    return <LoadingCard />
  }

  const valuesFields = hasCultureValues ? cultureValuesFields : cultureSkillsFields

  const onSelectGrade = (grade: GradeOption, field: CardField) => {
    if (hasCultureValues) {
      onPrefillCultureValue(values, grade, field.field)
      updateValueRating({ values, path: field.field })
    } else {
      const currentRating = get(values, `${field.field}.rating`)
      set(values, `${field.field}.rating`, grade.key)
      try {
        onPrefillWithGrade(
          values,
          grade,
          PerformanceReviewTypes.cultureFit,
          field.cardIndex,
        )
      } catch {
        set(values, `${field.field}.rating`, currentRating)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
  }

  const description = skillsPreferences?.company_values_validation_enabled ? (
    <Box
      p="s-16"
      mx="s-16"
      border={`1px solid ${Token.color.greyTone10}`}
      borderRadius={Token.radius.r16}
    >
      <Text>When rating the behaviours across all values:</Text>
      <List variant="compact" mt="s-8">
        <List.Item useIcon={<Gift color="green" size={16} />}>
          You can select <Text fontWeight="500">up to 3</Text> as{' '}
          <Text fontWeight="500">superpower</Text>
        </List.Item>
        <List.Item useIcon={<StarEmpty color="orange" size={16} />}>
          You must select <Text fontWeight="500">at least 2</Text> as{' '}
          <Text fontWeight="500">improvement focus</Text>
        </List.Item>
      </List>
    </Box>
  ) : undefined

  return (
    <Card
      data={values}
      renderExpandedContent={expContentField => (
        <CultureValues selectedField={expContentField} />
      )}
      type={CardContentTypes.VALUES}
      title="Values"
      icon="Heart"
      fields={valuesFields}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.rating
        if (!ratingValue) {
          return false
        }
        return hasCultureValues
          ? ratingValue === grade.key
          : getRoundedRating(ratingValue) === grade.key
      }}
      onSelectGrade={onSelectGrade}
      justification={
        hasCultureValues
          ? values?.review_data?.culture_values?.skipped_section_justification
          : values?.review_data?.culture_skills?.skipped_section_justification
      }
      additionalInfo={description}
      onHelpClick={() => onHelpClick?.(HelpTabs.Values)}
      headerRef={ref}
    />
  )
})
