import React, { useEffect, useState } from 'react'
import {
  PerformanceRating,
  Ratings,
  ReviewCategory,
  SummarySkillCardJustificationInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Box,
  Cell,
  Flex,
  InputGroup,
  Link,
  TableWidget,
  Text,
  VStack,
  Icon,
  Token,
  chain,
} from '@revolut/ui-kit'
import { get } from 'lodash'
import {
  CommonSectionProps,
  deliverablesRating,
  getRoundedRating,
  PerformanceRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { FeatureFlags } from '@src/store/auth/types'
import { getProbationJiraTickets, getProbationTickets } from '@src/api/probationReview'
import { Queries } from '@src/constants/api'
import produce from 'immer'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { useParams } from 'react-router-dom'
import set from 'lodash/set'
import { CellInsertParams, RowInterface } from '@src/interfaces/data'
import { getIconKeyByIssue } from '@src/utils/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  probationReviewDueColumn,
  probationReviewGoalColumn,
} from '@src/constants/columns/probation'
import { InfoOutline } from '@revolut/icons'
import { format } from 'date-fns'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { relationToString } from '@src/features/Scorecard/constants'

interface Props extends CommonSectionProps {
  category?: ReviewCategory
  cycleId?: number
  checkpointNum?: number
  comments?: SummarySkillCardJustificationInterface[]
}

const ROW = (jiraEnabled = true): RowInterface<DeliverableInterface> => ({
  cells: [
    {
      ...probationReviewGoalColumn,
      title: jiraEnabled ? 'Jira goal' : 'Goal',
      insert: ({ data }: CellInsertParams<DeliverableInterface>) => {
        const iconType = getIconKeyByIssue(data.issue_type)
        return jiraEnabled ? (
          <Flex alignItems="center">
            <Icon name={`${iconType}|image`} size={16} mr="s-8" />
            <Link href={data.url} target="_blank" rel="noopener norefferer">
              {data.key}
            </Link>
            : {data.title}
          </Flex>
        ) : (
          <Flex alignItems="center" gap="s-8">
            <Text>{data.title}</Text>
            {data.description && (
              <Tooltip placement="top" text={data.description}>
                <InfoOutline size={15} color={Token.color.greyTone50} />
              </Tooltip>
            )}
          </Flex>
        )
      },
      width: 100,
    },
    {
      ...probationReviewDueColumn,
      insert: ({ data }: CellInsertParams<DeliverableInterface>) => {
        return (
          <Box>{data.due_date ? format(new Date(data.due_date), 'd MMM yyyy') : '-'}</Box>
        )
      },
      width: 30,
    },
  ],
})

export const ProbationDeliverables = connect(
  ({
    reviewData,
    isViewMode = false,
    category,
    cycleId,
    checkpointNum,
    comments,
  }: Props) => {
    const { employeeId } = useParams<{ employeeId: string }>()
    const { query } = useQuery()
    const [loading, setLoading] = useState(true)
    const [goals, setGoals] = useState<DeliverableInterface[]>()

    const { data: performanceSettings } = useGetPerformanceSettings()
    const featureFlags = useSelector(selectFeatureFlags)
    const pipJiraDisabled = featureFlags.includes(
      FeatureFlags.PipGoalsJiraIntegrationDisabled,
    )
    const jiraIntegrationEnabled =
      performanceSettings?.enable_probation_and_pip_goals_via_jira &&
      (category !== ReviewCategory.PIP_V2 || !pipJiraDisabled)

    const filterCategory = category === ReviewCategory.PIP_V2 ? 'PIP' : category
    const getTicketsFilters = () => {
      const filters = [
        {
          filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
          columnName: 'employee__id',
        },
      ]
      const cycleProbationFilter = [
        {
          filters: [{ name: filterCategory || '', id: filterCategory || '' }],
          columnName: 'category',
        },
      ]
      const cyclePipFilter = [
        {
          filters: [{ name: `E-${cycleId}`, id: `E-${cycleId}` }],
          columnName: 'review_cycle__id',
        },
      ]
      const additionalFilters =
        category === ReviewCategory.PIP_V2 && cycleId
          ? cyclePipFilter
          : cycleProbationFilter

      return [...filters, ...additionalFilters]
    }

    useEffect(() => {
      const fetchTickets = async () => {
        if (employeeId && category) {
          try {
            const { data } = await getProbationJiraTickets(
              category,
              Number(employeeId),
              isViewMode ? query[Queries.CheckpointNumber] : checkpointNum,
              isViewMode ? query[Queries.ReviewerId] : undefined,
            )

            let jiraConsideredGoals = data.goals ? [...data.goals] : []
            if (jiraIntegrationEnabled === false && employeeId) {
              const { data: tickets } = await getProbationTickets(
                Number(employeeId),
                getTicketsFilters(),
              )
              if (tickets?.results.length) {
                jiraConsideredGoals = produce(tickets?.results, draft => {
                  draft.forEach(item => {
                    item.title = item.name || ''
                    item.due_date = item.deadline_date_time
                  })
                })
              }
            }

            setGoals(jiraConsideredGoals)
          } finally {
            setLoading(false)
          }
        }
      }

      fetchTickets()
    }, [
      query[Queries.CheckpointNumber],
      query[Queries.ReviewerId],
      jiraIntegrationEnabled,
      employeeId,
    ])

    if (!reviewData || loading) {
      return null
    }

    const getFieldDetails = (option: {
      key: string
      text: string
      description: string[]
    }) => {
      const ratingValue = get(
        reviewData,
        'calculated_deliverables_ratings.recommended_rating',
      )
      const expectedValue = get(
        reviewData,
        'calculated_deliverables_ratings.rating_expectation',
      )
      const isChecked =
        deliverablesRating.find(item => item.key === ratingValue)?.key === option.key
      const exceedingExpectation =
        isChecked &&
        PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
          PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

      return { isChecked, exceedingExpectation, ratings: [] }
    }
    const getViewFieldDetails = (option: {
      key: string
      text: string
      description: string[]
    }) => {
      const ratings =
        get(
          reviewData,
          'calculated_deliverables_ratings.recommended_rating.ratings',
        )?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const expectedValue = get(
        reviewData,
        'calculated_deliverables_ratings.rating_expectation.rating',
      )
      const isChecked = !!ratings.length
      const exceedingExpectation =
        isChecked &&
        PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
          PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

      return { isChecked, exceedingExpectation, ratings }
    }

    return (
      <Cell>
        <VStack space="s-16" data-testid="deliverables-section">
          <Text variant="h4">Contribution and impact made towards deliverables</Text>
          {goals && (
            <TableWidget style={{ padding: 0 }}>
              <TableWidget.Info />
              <TableWidget.Table>
                <AdjustableTable<DeliverableInterface>
                  name={TableNames.ProbationGoals}
                  dataType="Goal"
                  row={ROW(jiraIntegrationEnabled)}
                  data={goals}
                  count={goals.length}
                  noDataMessage="No goals defined"
                  hideCountAndButtonSection
                />
              </TableWidget.Table>
            </TableWidget>
          )}
          <InputGroup>
            {deliverablesRating.map((option, optionIdx) => {
              const { isChecked, exceedingExpectation, ratings } = isViewMode
                ? getViewFieldDetails(option)
                : getFieldDetails(option)
              return (
                <DeliverablesGrade
                  key={optionIdx}
                  isViewMode={isViewMode}
                  isChecked={isChecked}
                  exceedingExpectation={exceedingExpectation}
                  ratings={ratings}
                  option={option}
                  onChange={() => {
                    set(
                      reviewData,
                      'calculated_deliverables_ratings.recommended_rating',
                      option.key,
                    )
                  }}
                />
              )
            })}
          </InputGroup>
          {isViewMode ? (
            <VStack space="s-8">
              {comments?.map(comment => (
                <Box
                  p="s-16"
                  key={comment.review.id}
                  border={`1px solid ${Token.color.greyTone10}`}
                  borderRadius={Token.radius.r16}
                >
                  <VStack space="s-8">
                    <Text variant="primary">
                      {chain(
                        comment.review.reviewer.full_name,
                        relationToString(comment.review.reviewer_relation, true),
                      )}
                    </Text>
                    <Text variant="secondary">{comment.value}</Text>
                  </VStack>
                </Box>
              ))}
            </VStack>
          ) : (
            <LapeNewTextArea
              rows={2}
              label="Justification / evidence or specific examples"
              name="review_data.calculated_deliverables_ratings.recommended_rating_comment"
            />
          )}
        </VStack>
      </Cell>
    )
  },
)
