import React from 'react'
import { PerformanceRating, Ratings } from '@src/interfaces/performance'
import { connect } from 'lape'
import { Box, Cell, chain, Flex, InputGroup, Text, Token, VStack } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { get, set } from 'lodash'
import {
  CommonSectionProps,
  getRoundedRating,
  kpiRating,
  PerformanceRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { DeliverablesGrade } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { relationToString } from '@src/features/Scorecard/constants'

export const KPI = connect(({ reviewData, isViewMode = false }: CommonSectionProps) => {
  if (!reviewData) {
    return null
  }
  const getFieldDetails = (option: {
    key: string
    text: string
    description: string[]
  }) => {
    const ratingValue = get(reviewData, 'kpis_section.recommended_rating')
    const expectedValue = get(reviewData, 'kpis_section.rating_expectation')
    const isChecked = kpiRating.find(item => item.key === ratingValue)?.key === option.key
    const exceedingExpectation =
      isChecked &&
      PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
        PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

    return { isChecked, exceedingExpectation, ratings: [] }
  }

  const getViewFieldDetails = (option: {
    key: string
    text: string
    description: string[]
  }) => {
    const ratings =
      get(reviewData, 'kpis_section.recommended_ratings')?.filter((rating: Ratings) => {
        return getRoundedRating(rating.value) === option.key
      }) || []
    const expectedValue = get(reviewData, 'kpis_section.rating_expectation')
    const isChecked = !!ratings.length
    const exceedingExpectation =
      isChecked &&
      PerformanceRatingToGraphNumber[option.key as PerformanceRating] >
        PerformanceRatingToGraphNumber[expectedValue as PerformanceRating]

    return { isChecked, exceedingExpectation, ratings }
  }

  return (
    <Cell>
      <VStack width="100%" space="s-8">
        <Flex backgroundColor={Token.color.greyTone2} borderRadius={Token.radius.r16}>
          <InputGroup>
            {kpiRating.map((option, optionIdx) => {
              const { isChecked, exceedingExpectation, ratings } = isViewMode
                ? getViewFieldDetails(option)
                : getFieldDetails(option)
              return (
                <DeliverablesGrade
                  key={optionIdx}
                  isViewMode={isViewMode}
                  isChecked={isChecked}
                  exceedingExpectation={exceedingExpectation}
                  ratings={ratings}
                  option={option}
                  onChange={() => {
                    set(reviewData, 'kpis_section.recommended_rating', option.key)
                  }}
                />
              )
            })}
          </InputGroup>
        </Flex>
        {isViewMode ? (
          <VStack space="s-8">
            {reviewData?.kpis_section?.comments?.map(comment => (
              <Box
                p="s-16"
                key={comment.review.id}
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <VStack space="s-8">
                  <Text variant="primary">
                    {chain(
                      comment.review.reviewer.full_name,
                      relationToString(comment.review.reviewer_relation, true),
                    )}
                  </Text>
                  <Text variant="secondary">{comment.value}</Text>
                </VStack>
              </Box>
            ))}
          </VStack>
        ) : (
          <LapeNewTextArea
            rows={2}
            label="Justification / evidence or specific examples"
            name="review_data.kpis_section.comment"
            data-testid="comment"
          />
        )}
      </VStack>
    </Cell>
  )
})
