import React from 'react'
import { ReviewSummaryInterface, SectionType } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Target } from '@revolut/icons'
import {
  CardContentTypes,
  GradeOption,
  kpiRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'

export const DeliverablesCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const hasKPI = !!values.summary?.kpis_section

  const deliverablesGrades =
    values.summary?.deliverables?.cards?.[0].sections?.[0]?.type ===
    SectionType.SingleChoice
      ? values.summary.deliverables.cards[0].sections[0].options.map(option => ({
          key: option.key,
          text: PerformanceRatingTitle[option.key],
          description: [option.text],
        }))
      : []

  return (
    <>
      {hasKPI ? (
        <Card
          data={values}
          renderExpandedContent={() => <KPI isViewMode reviewData={values.summary} />}
          type={CardContentTypes.KPI}
          title="Deliverables"
          icon={Target}
          fields={[
            {
              field: 'summary.kpis_section.rating',
              title: 'Contribution and impact',
              grades: kpiRating as GradeOption[],
            },
          ]}
          finalRating={values.summary?.kpis_section?.rating}
          isViewMode
        />
      ) : (
        <Card
          data={values}
          renderExpandedContent={() => (
            <Deliverables isViewMode reviewData={values.summary} />
          )}
          type={CardContentTypes.DELIVERABLES}
          title="Deliverables"
          icon={Target}
          fields={[
            {
              field: `summary.deliverables.rating`,
              title: 'Contribution and impact',
              grades: deliverablesGrades,
            },
          ]}
          justification={values?.summary?.deliverables?.skipped_section_justifications}
          finalRating={values.summary?.deliverables?.rating}
          isViewMode
        />
      )}
    </>
  )
})
