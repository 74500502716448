import React, { useState } from 'react'
import { connect } from 'lape'
import { Flex, Sticky, VStack } from '@revolut/ui-kit'
import styled from 'styled-components'
import { DeliverablesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/DeliverablesCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Visibility } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Visibility'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPIsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/KPIsCard'

export const StyledSticky = styled(Sticky)`
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const getRecommendationAndType = (values: ReviewScorecardInterface) => {
  const lmRecommendation = values.review_data.line_manager_extra_section
  const fmRecommendation = values.review_data.functional_manager_extra_section
  const peerRecommendation = values.review_data.peer_extra_section
  const probationPipRecommendation = values.review_data.recommendation_extra_section
  const obsoletePipRecommendation = values.review_data.pip_extra_section

  if (lmRecommendation) {
    return {
      recommendation: lmRecommendation,
      type: 'lm' as RecommendationType,
      field: 'review_data.line_manager_extra_section',
    }
  }
  if (fmRecommendation) {
    return {
      recommendation: fmRecommendation,
      type: 'fm' as RecommendationType,
      field: 'review_data.functional_manager_extra_section',
    }
  }
  if (peerRecommendation) {
    return {
      recommendation: peerRecommendation,
      type: 'peer' as RecommendationType,
      field: 'review_data.peer_extra_section',
    }
  }
  if (obsoletePipRecommendation) {
    return {
      recommendation: obsoletePipRecommendation,
      type: 'pip_v1' as RecommendationType,
      field: 'review_data.pip_extra_section',
    }
  }
  if (probationPipRecommendation) {
    return {
      recommendation: probationPipRecommendation,
      type: 'pip' as RecommendationType,
      field: 'review_data.recommendation_extra_section',
    }
  }

  return null
}

export const ScorecardContent = connect(() => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()

  const recommendationSection = getRecommendationAndType(values)

  const onHelpTooltipClick = (helpTab: HelpTabs) => {
    if (isHelpOpen) {
      setHelpOpen(undefined)
    }
    setHelpOpen(helpTab)
  }

  return (
    <>
      <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
      <VStack space="s-16">
        <KPIsCard onHelpClick={onHelpTooltipClick} />
        <DeliverablesCard onHelpClick={onHelpTooltipClick} />
        <SkillsCard onHelpClick={onHelpTooltipClick} />
        <ManagerSkillsCard onHelpClick={onHelpTooltipClick} />
        <CultureValuesCard onHelpClick={onHelpTooltipClick} />
        {recommendationSection && (
          <BarRaiser
            isViewMode={false}
            questions={recommendationSection.recommendation.keeper_test_section.questions}
            type={recommendationSection.type}
            field={recommendationSection.field}
          />
        )}
        <Feedback
          recommendationData={recommendationSection?.recommendation}
          type={recommendationSection?.type}
          onFeedbackClick={() => {}}
        />
        <AdditionalQuestions data={values.review_data} isViewMode={false} />
        <Visibility />
        <PageActions maxWidth="100%">
          <Flex gap="s-8" justifyContent="center">
            <SaveDraftButton />
            <SubmitButton />
          </Flex>
        </PageActions>
      </VStack>
    </>
  )
})
